<template>
  <v-container>
    <v-card outlined class="pa-4 mt-4" v-if="opendiv199">
      <v-row align="center" justify="center">
        <v-col cols="12">
          <h3>Export ข้อมูล</h3>
        </v-col>
        <v-col cols="12" class="mb-4">
          <ExportallPeppleFinalRawReport :itemExportData="exportPeople" />
        </v-col>
        <v-col cols="12" class="mb-4">
          <ExportallOfficerFinalRawReport :itemExportData="exportOfficer" />
        </v-col>
      </v-row>
    </v-card>
    <v-card outlined class="pa-4 mt-4" v-else>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <h3>ระดับท่านอยู่นอกเหนือสิทธิการใช้งาน ไม่สามารถดูเมนูนี้ได้</h3>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { Decode, Encode } from "@/services";
import ExportallPeppleFinalRawReport from "@/views/ExportExcel/ExportallPeppleFinalRawReport";
import ExportallOfficerFinalRawReport from "@/views/ExportExcel/ExportallOfficerFinalRawReport";

export default {
  components: { ExportallPeppleFinalRawReport, ExportallOfficerFinalRawReport },
  data() {
    return {
      exportPeople: [],
      exportOfficer: [],
      opendiv199: false,
    };
  },
  async created() {
    this.getUser();
    const response1 = await this.axios.get(
      `${process.env.VUE_APP_API}/form/allPeppleFinalRawReport`
    );
    console.log("getallPeppleFinalRawReportAll", response1.data.data);
    this.exportPeople = response1.data.data;
    const response2 = await this.axios.get(
      `${process.env.VUE_APP_API}/form/allOfficerFinalRawReport`
    );
    console.log("exportOfficer", response2.data.data);
    this.exportOfficer = response2.data.data;
  },
  methods: {
    async getUser() {
      this.loading = true;
      this.checklogin = JSON.parse(Decode.decode(localStorage.getItem("user")));
      console.log("checklogincheckloginchecklogin", this.checklogin);

      if (
        this.checklogin.userType == "1" ||
        this.checklogin.userType == "2" ||
        this.checklogin.userType == "99"
      ) {
        this.opendiv199 = true;
        this.loading = false;
      }
    },
  },
};
</script>