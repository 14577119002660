<template>
  <v-row align="center" justify="center" class="text-center">
    <v-btn class="ml-4"
      ><download-excel
        class="btn btn-default"
        :data="itemExportData"
        :fields="json_fields"
        worksheet="My Worksheet"
        name="Download แบบประเมินความพึงพอใจของประชาชน.xls"
        :header="'แบบประเมินความพึงพอใจของประชาชนในชุมชนที่ร่วมโครงการ'"
      >
        <img src="@/assets/Excel-Logo.png" width="50px" />
        Download แบบประเมินความพึงพอใจของประชาชน
      </download-excel></v-btn
    >
  </v-row>
</template>
<script>
import moment from "moment";
export default {
  props: ["itemExportData"],
  data() {
    return {
      json_fields: {
        // commentDesc: null
        // createdAt: "2021-09-13T09:06:40.000Z"
        // deviceId: "0506011610295373662032028453736620106403603216315238878961ycVHxNUNk"
        // id: 90

        // question1: "5"
        // question2: "5"
        // question3: "5"
        // question4: "5"
        // question5: "5"
        // question6: "5"
        // question7: "4"
        // question8: "5"
        // question9: "5"
        // question10: "5"

        ชื่อสถานี: "station",
        จังหวัด: "province",
        ภาค: "area",
        วันที่: {
          field: "createdAt",
          callback: (value) => {
            return moment(value).format("MM/DD/YYYY hh:mm");
          },
        },

        ท่านมีความเข้าใจการดำเนินงานของโครงการชุมชนยั่งยืน: "question1",
        ท่านมีความเชื่อมั่นว่าโครงการชุมชนยั่งยืนช่วยแก้ปัญหายาเสพติดในพื้นที่ได้:
          "question2",
        ท่านมีส่วนร่วมกับกิจกรรมในโครงการชุมชนยั่งยืน: "question3",
        ท่านมีความรู้สึกที่ดีในการทำงานของเจ้าหน้าที่ตำรวจชุดปฏิบัติการโครงการชุมชนยั่งยืน:
          "question4",
        หัวหน้าสถานีตำรวจได้เข้ามาขับเคลื่อนกับโครงการชุมชนยั่งยืน: "question5",
        ท่านมีความพึงพอใจในภาพรวมต่อโครงการชุมชนยั่งยืน: "question6",
        การแพร่ระบาดยาเสพติดและผู้เสพลดลง: "question7",
        อาชญากรรมในชุมชนลดลง: "question8",
        "โครงการชุมชนยั่งยืนเสร็จแล้ว คนในชุมชนร่วมมือป้องกันแก้ไขปัญหายาเสพติดในชุมชน":
          "question9",
        "โครงการชุมชนยั่งยืนเสร็จแล้ว ตำรวจยังติดตามดูแลอย่างต่อเนื่อง":
          "question10",
        ข้อเสนอแนะ: "commentDesc",
      },

      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      list1: [],
    };
  },
  async created() {
    // this.getREportXray();
  },
  methods: {
    // async getREportXray() {
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/form/drugrelatedReport`
    //   );
    //   console.log("data", response.data.data[0]);
    //   this.list1 = response.data.data[0];
    //   this.list1.count = parseInt(i) + 1;
    // },
  },
};
</script>
