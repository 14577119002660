<template>
  <v-row align="center" justify="center" class="text-center">
    <v-btn class="ml-4"
      ><download-excel
        class="btn btn-default"
        :data="itemExportData"
        :fields="json_fields"
        worksheet="My Worksheet"
        name="Download แบบประเมินความพึงพอใจการปฏิบัติงานของชุดปฏิบัติ"
        :header="'แบบประเมินความพึงพอใจการปฏิบัติงานของชุดปฏิบัติการในพื้นที่'"
      >
        <img src="@/assets/Excel-Logo.png" width="50px" />
        Download แบบประเมินความพึงพอใจการปฏิบัติงานของชุดปฏิบัติ
      </download-excel></v-btn
    >
  </v-row>
</template>
<script>
import moment from "moment";
export default {
  props: ["itemExportData"],
  data() {
    return {
      json_fields: {
        // commentDesc: null
        // createdAt: "2021-09-13T09:06:40.000Z"
        // deviceId: "0506011610295373662032028453736620106403603216315238878961ycVHxNUNk"
        // id: 90

        // question1: "5"
        // question2: "5"
        // question3: "5"
        // question4: "5"
        // question5: "5"
        // question6: "5"
        // question7: "4"
        // question8: "5"
        // question9: "5"
        // question10: "5"

        ชื่อสถานี: "station",
        จังหวัด: "province",
        ภาค: "area",
        วันที่: {
          field: "createdAt",
          callback: (value) => {
            return moment(value).format("MM/DD/YYYY hh:mm");
          },
        },
        "ความรู้ ความเข้าใจก่อนลงปฏิบัติงานในพื้นที่": "question1",
        ความเอาใจใส่ของผู้บังคับบัญชา: "question2",
        ความเหมาะสมของกิจกรรมที่ดำเนินตามแผนปฏิบัติการ: "question3",
        ผลสำเร็จในการดำเนินงานตามแผนปฏิบัติการที่กำหนด: "question4",
        การส่งเสริมสนับสนุนการปฏิบัติงานจากหน่วยงานร่วมชุดปฏิบัติการ:
          "question5",
        ความร่วมมือของประชาชนในพื้นที่ปฏิบัติงาน: "question6",
        ความพึงพอใจในการเข้าร่วมชุดปฏิบัติการ: "question7",
        ข้อเสนอแนะ: "commentDesc",
      },

      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      list1: [],
    };
  },
  async created() {
    // this.getREportXray();
  },
  methods: {
    // async getREportXray() {
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/form/drugrelatedReport`
    //   );
    //   console.log("data", response.data.data[0]);
    //   this.list1 = response.data.data[0];
    //   this.list1.count = parseInt(i) + 1;
    // },
  },
};
</script>
